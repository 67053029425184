import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Lottie from 'lottie-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';

import logo from '../assets/image/logo.png';
import banner from '../assets/image/banner.jpg';

import animLoading from '../assets/anim/anim-pageload.json';
import animBtnLoading from '../assets/anim/anim-btnLoading.json';

import ServiceVerify from '../services/serviceVerify';

export default function PageVerify() {

    const { token } = useParams();

    const [projectToken, setProjectToken] = useState('');
    const [did, setDid] = useState('');
    const [opration, setOpration] = useState('');

    const [loading, setLoading] = useState(true);
    const [submit, setSubmit] = useState(false);

    const [isValid, setIsValid] = useState(false);
    const [isDone, setIsDone] = useState(false);

    const alert = useAlert();
    const verifyService = new ServiceVerify();

    const formVSchema = Yup.object().shape({
        npass: Yup.string().required('This information is required'),
        cpass: Yup.string().required('This information is required')
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            npass: '',
            cpass: ''
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            if (values.cpass === values.npass) {
                setSubmit(true);
                if (opration === "profileCreate" || opration === "profileReset") {
                    let body = {
                        'uid': did,
                        'password': values.npass
                    }
                    verifyService.PasswordUpdate(body, projectToken).then((res) => {
                        if (!res.status) {
                            setIsValid(false);
                        }
                        setIsDone(true);
                        setSubmit(false);
                    });
                } else {
                    setIsDone(true);
                    setIsValid(false);
                    setSubmit(true);
                }
            } else {
                alert.show("Password did not matched!", { type: 'error' });
            }
        },
    });

    const laodData = () => {
        (async function () {
            let bodyConfig = {
                "projectId": "f72e22d7ca05ce273a289a5ad28346c7",
                "APIKey": "d105d8258ae601415202e0d533f01fe3a78576d0"
            };
            var resConfig = await verifyService.initProject(bodyConfig, '');
            if (resConfig.status) {
                setProjectToken(resConfig.data.token);
                let body = { "token": token };
                var res = await verifyService.verifyToken(body, '');
                if (res.status) {
                    setIsValid(true);
                    setOpration(res.data.opration);
                    setDid(res.data.uid);
                } else {
                    setLoading(false);
                    setIsValid(false);
                }
            } else {
                setLoading(false);
                setIsValid(false);
            }
        })();
    }

    const doOpration = () => {
        switch (opration) {
            case "profileCreate":
                setLoading(false);
                break;
            case "profileReset":
                setLoading(false);
                break;
            default:
                setLoading(false);
                setIsValid(false);
                break;
        }
    }

    useEffect(() => {
        if (loading) {
            laodData();
        }
    }, [loading]);

    useEffect(() => {
        if (opration !== "") {
            doOpration();
        }
    }, [opration]);

    return (
        <div className='w-full h-screen bg-gray-50'>
            <header className="bg-white absolute w-full px-[2%] md:px-[6%] lg:px-[12%] min-h-[24%]">
                <img src={logo} alt="logo" className='w-[240px] mt-[28px]' />
            </header>
            <main className="w-full z-50 absolute top-[18%] h-[74%]">
                <div className="w-[96%] md:w-[90%] lg:w-[80%] bg-white m-auto rounded-md shadow-lg min-h-full flex">
                    <section className="flex-grow flex flex-col justify-center items-center md:w-full lg:w-2/4">
                        <div className='w-full flex flex-col justify-center items-center'>
                            {loading && <Lottie animationData={animLoading} className="intro-y w-[60%] mx-auto mb-20" loop={true} />}
                            {
                                !loading && <>
                                    {
                                        !isValid && <div className='mx-8 text-center bg-red-200 p-4 rounded-md'>
                                            <h1 className='text-3xl text-red-600 font-medium'>Token expired!</h1>
                                            <p className='mt-4 text-red-600'>I'm sorry, but it appears that the token you provided has expired or invalid. To proceed, please request a new token to your email and use it within the given timeframe.</p>
                                        </div>
                                    }
                                    {
                                        (isValid && isDone && (opration === "profileCreate")) && <div className='mx-8 text-center bg-blue-100 p-4 rounded-md'>
                                            <h1 className='text-3xl text-prime font-medium'>Password creation successful!</h1>
                                            <p className='mt-4 text-prime'>Your password has been created. Please use your new password for all future logins.</p>
                                        </div>
                                    }
                                    {
                                        (isValid && isDone && (opration === "profileReset")) && <div className='mx-8 text-center bg-blue-100 p-4 rounded-md'>
                                            <h1 className='text-3xl text-prime font-medium'>Password reset successful!</h1>
                                            <p className='mt-4 text-prime'>Your password has been reset. Please use your new password for all future logins.</p>
                                        </div>
                                    }
                                    {
                                        (isValid && !isDone) && <form className="mx-8 w-[60%]" onSubmit={handleSubmit}>
                                            <div>
                                                <h1 className="text-2xl text-black font-serif font-medium">SET NEW PASSWORD</h1>
                                            </div>
                                            <div className="flex flex-col mt-8">
                                                <label className="text-sm text-gray-900 font-serif">New password
                                                    <sup className="text-red-600">*</sup>
                                                </label>
                                                <input type="password" placeholder="i.e. xxxxxxxx" id="npass" value={values.npass} onChange={handleChange} className="w-full h-11 rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1" autoComplete="off" />
                                                {(errors.npass && touched.npass) && <p className='text-xs text-red-400 mt-1'>{errors.npass}</p>}
                                            </div>
                                            <div className="flex flex-col mt-4">
                                                <label className="text-sm text-gray-900 font-serif">Confirm password
                                                    <sup className="text-red-600">*</sup>
                                                </label>
                                                <input type="password" placeholder="i.e. xxxxxxxx" id="cpass" value={values.cpass} onChange={handleChange} className="w-full h-11 rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1" autoComplete="off" />
                                                {(errors.cpass && touched.cpass) && <p className='text-xs text-red-400 mt-1'>{errors.cpass}</p>}
                                            </div>
                                            <button type="submit" className="mt-8 text-xs font-bold text-white rounded bg-prime hover:bg-primeLight w-full h-10 font-mono flex items-center justify-center" disabled={submit}>
                                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" />}
                                                {!submit && "UPDATE PASSWORD"}
                                            </button>
                                        </form>
                                    }
                                </>
                            }
                        </div>
                    </section>
                    <section className="flex-grow justify-center items-center w-2/4 hidden lg:block">
                        <img src={banner} alt="banner-image" className='w-[90%] m-auto' />
                    </section>
                </div>
            </main>
        </div>
    )
}