import React, { useEffect } from 'react';
import Lottie from 'lottie-react';
import animSad from '../assets/anim/anim-sad.json';

export default function Page404() {

    const handleClick = () => {
        window.location.replace('https://app.ishcallsign.com');
    }

    useEffect(() => {
        handleClick();
    }, [])


    return (
        <>
            <div className="w-screen h-screen overflow-hidden flex items-center justify-center">
                <div className="container">
                    <Lottie animationData={animSad} className="intro-y w-24 mx-auto mb-20" loop={true} />
                    <h2 className="intro-y text-red-600 text-center lg:text-big text-9xl font-serif font-bold">404</h2>
                    <p className=" intro-y text-gray-900 lg:text-lg text-base text-center font-serif font-normal w-4/5 mx-auto">The page you are attempting to reach is currently not available.<br />This may be because the page does not exist or has been moved.</p>
                    <div className="intro-y text-white lg:text-xl text-base text-center font-serif bg-red-500 hover:bg-red-600 cursor-pointer rounded-md lg:w-80 w-60 mx-auto mt-20 py-2" onClick={handleClick}>RETURN TO HOME</div>
                </div>
            </div>
        </>
    )
}
